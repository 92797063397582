<template>
	<div class="ratio-frame" :class="{ square }">
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		square: Boolean
	}
}
</script>

<style lang="scss" scoped>
.ratio-frame {
	position: relative;
	max-width: 100%;

	&::before {
		content: "";
		display: block;
		padding-top: 56.25%;
	}

	&.square {
		&::before {
			padding-top: 100%;
		}
	}

	> *:first-child {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	> *:not(:first-child) {
		display: none;
	}
}
</style>